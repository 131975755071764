import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"

import styles from "./404.module.scss"

const NotFoundPage = () => (
  <Layout theme="light">
    <Hero heroTitle="Page not found" theme="light" graph="radar2" />
    <div className="grid">
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div className={styles.page}>
          <div>
            <p>
              Well this is embarrassing. Looks like we hit some bad data, and
              the page you requested can't be found.
            </p>
            <p>
              Return to the safety of <Link to="/">our homepage</Link>, or read
              more about the{" "}
              <a href="https://www.watershedlrs.com/blog/product/xapi-data-transformation/what-is-xapi-governance/">
                importance of having good data.
              </a>
            </p>
          </div>
          <img
            src="/startrek.gif"
            alt="Data Star Trek animated gif"
            loading="lazy"
          />
        </div>
      </div>
    </div> 
  </Layout>
)

export default NotFoundPage
